// tslint:disable: max-classes-per-file

import { LoggedInUser } from "@mksap/types/loggedInUser";
import { appOptions } from "../appOptions";
import { makeAjaxPromise } from "../utils/makeAjaxPromise";

let LoginBackend: ILoginBackend;

interface ILoginBackend {
	logIn(username: string, password: string): Promise<LoggedInUser>;
	logOut(): void;
	getSavedLoggedInUser(): LoggedInUser;
}

abstract class BaseLoginBackend {
	async doLogIn(
		username: string,
		password: string,
		generateToken: boolean,
	): Promise<LoggedInUser> {
		//$.ajax({url: '/api/signin.json', type: 'POST', data: {username: 'bradleyl', password: 'password', generate_token: 1}}).success(function(data){console.log(data)})
		try {
			const userData = await makeAjaxPromise<LoggedInUser>({
				url: appOptions.ajaxPrefix + "/api/signin.json",
				type: "POST",
				data: JSON.stringify({
					username,
					password,
					generate_token: generateToken,
				}),
			});

			try {
				const userJSON = JSON.stringify(userData);
				localStorage.setItem("loggedInUser", userJSON);
			} catch (e) {
				// Ignore errors and continue
			}

			return userData;
		} catch (err) {
			const response: Response = err.response;
			(window as any).hresponse = response;

			const defaultErrorMessage = "There was an error logging in.";
			if (!response) {
				throw new Error(defaultErrorMessage);
			} else if (response.status === 0) {
				throw new Error(
					"Could not reach MKSAP 19 servers. Please ensure you are connected to the internet and try again.",
				);
			} else {
				if (err.friendlyErrorMessage) {
					throw err;
				} else {
					throw new Error(defaultErrorMessage);
				}
			}
		}
	}
}

if (appOptions.native) {
	class NativeLoginBackend extends BaseLoginBackend implements ILoginBackend {
		async logIn(username: string, password: string): Promise<LoggedInUser> {
			return this.doLogIn(username, password, true);
		}

		logOut() {
			// SyncBackend.resetSyncId();
			localStorage.removeItem("loggedInUser");
			(window as any).location = "index.html";
		}

		getSavedLoggedInUser() {
			try {
				const userJSON = localStorage.getItem("loggedInUser");
				if (userJSON) {
					return JSON.parse(userJSON);
				}
			} catch (e) {
				// Catch errors and allow undefined to be returned
			}
		}
	}

	LoginBackend = new NativeLoginBackend();
} else {
	class WebLoginBackend extends BaseLoginBackend implements ILoginBackend {
		async logIn(username: string, password: string): Promise<LoggedInUser> {
			return this.doLogIn(username, password, false);
		}

		logOut() {
			(window as any).location = "/signout";
		}

		getSavedLoggedInUser(): never {
			throw new Error("Not implemented in WebLoginBackend");
		}
	}

	LoginBackend = new WebLoginBackend();
}

export default LoginBackend;
