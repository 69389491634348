import React, { useEffect, useState } from "react";
import classNames from "classnames";
import imagePath from "utils/imagePath";
import "./LoadingMessage.scss";
import { initialLoadingScreenDelayMs } from "@mksap/utils/withLoadingDelay";

export interface LoadingMessageProps {
	image?: string;
	alt?: string;
	size?: "sm" | "lg";
	message?: string;
	delayLoadingMessage?: boolean;
}

export const LoadingMessage: React.FC<LoadingMessageProps> = ({
	image = imagePath("acpMKSAP-logo-4c.svg"),
	alt = "ACP MKSAP",
	size = "sm",
	message = "Loading... Please wait",
	delayLoadingMessage = true,
}) => {
	// If delayLoadingMessage is true, avoid rendering the loading message and animation until
	// this component has been mounted for some minimal amount of time.
	const [showMessage, setShowMessage] = useState(!delayLoadingMessage);

	useEffect(() => {
		if (showMessage) {
			return;
		}
		const timeoutId = setTimeout(() => {
			setShowMessage(true);
		}, initialLoadingScreenDelayMs);
		return () => {
			clearTimeout(timeoutId);
		};
	}, [showMessage]);

	if (!showMessage) {
		return null;
	}
	return (
		<div className={classNames("loading", size)}>
			{size === "lg" && (
				<img className="mksap-acp-brand" src={image} alt={alt} />
			)}
			{message && <div className="loading-message">{message}</div>}
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
			<span />
		</div>
	);
};

export const TrackerLoadingMessage: React.FC<LoadingMessageProps> = (props) => {
	return (
		<LoadingMessage
			image={imagePath("logo-acp-tracker-4c.png")}
			alt="ACP MKSAP Tracker"
			{...props}
		/>
	);
};
