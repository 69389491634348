/* eslint-disable no-restricted-globals */
declare var PRODUCTION: boolean;
declare var AJAX_PREFIX: string;
declare var cordova: any;

// Set these values to _something_ for the unit tests
(window as any).PRODUCTION = (window as any).PRODUCTION;
(window as any).AJAX_PREFIX = (window as any).AJAX_PREFIX;

export interface AppOptions {
	readonly native: boolean;
	readonly production: boolean;
	readonly serverUrl: string;
	readonly ajaxPrefix: string;
	readonly apiPrefix: string;

	readonly remoteBaseFigureUrl: string;
	readonly baseFigureUrl: string;
	readonly forceLoadVideosRemotely: boolean;

	readonly inCordova: boolean;
	readonly inElectron: boolean;
	readonly historyOptions: {
		root: string;
	};
	readonly historyType: "memory" | "hash" | "browser";
	readonly syncEnabled: boolean;
	readonly inIOSApp?: boolean;

	readonly appVersion?: string;
	readonly gaTrackingId?: string;
	// The hostname to use in google analytics for the apps
	readonly gaTrackingHostname?: string;

	readonly hideStoreLinks?: boolean;

	// Define getContentPrefix as a function, since Android needs cordova to be loaded before it is available.
	getContentPrefix(): string;
}

abstract class BaseAppOptions implements Partial<AppOptions> {
	production = PRODUCTION;
	// remoteBaseFigureUrl = "https://d24q9ddxruqo1a.cloudfront.net/hashed_figures/";
	remoteBaseFigureUrl =
		"https://s3.amazonaws.com/acp-mksap-assets.acponline.org/hashed_figures/";
	forceLoadVideosRemotely = false;
	ajaxPrefix = "";
	apiPrefix = this.ajaxPrefix + "/api";
}

class WebAppOptions extends BaseAppOptions implements AppOptions {
	private readonly inWebSyncTestMode =
		location.pathname.startsWith("/app_with_sync");

	readonly native = false;
	readonly historyOptions = {
		root: this.inWebSyncTestMode ? "/app_with_sync" : "/app",
	};
	readonly historyType = "browser";

	readonly baseFigureUrl = this.remoteBaseFigureUrl;

	readonly serverUrl = "";
	readonly inCordova = false;
	readonly inElectron = false;
	readonly syncEnabled = this.inWebSyncTestMode;

	getContentPrefix(): string {
		return this.apiPrefix;
	}
}

class NativeAppOptions extends BaseAppOptions {
	readonly native = true;
	readonly syncEnabled = true;
	readonly appVersion = "0.1.0";
	readonly historyType = "hash";

	// if (/android/i.exec(window.navigator.userAgent)) {
	// 	appOptions.baseFigureUrl = "content://org.acponline.mksap17/figure-images/";
	// }
	readonly baseFigureUrl = "figure-images/";

	readonly serverUrl = this.getServerUrl();
	readonly ajaxPrefix = this.getAjaxPrefix();
	readonly apiPrefix = this.ajaxPrefix + "/api";
	readonly gaTrackingId = this.getGaTrackingId();
	readonly gaTrackingHostname = PRODUCTION
		? "mksap19-native"
		: "mksap19-native-dev";

	private getServerUrl() {
		const productionServerUrlPrefix = "https://mksap.acponline.org";

		const ajaxPrefix = AJAX_PREFIX || (window as any).AJAX_PREFIX;
		if (this.production || !ajaxPrefix) {
			return productionServerUrlPrefix;
		} else {
			return ajaxPrefix;
		}
	}

	private getAjaxPrefix() {
		const productionAjaxPrefix = "https://api.mksap.acponline.org";

		const ajaxPrefix = AJAX_PREFIX || (window as any).AJAX_PREFIX;
		if (this.production || !ajaxPrefix) {
			return productionAjaxPrefix;
		} else {
			return ajaxPrefix;
		}
	}

	private getGaTrackingId(): string {
		return "UA-2003141-34"; // Used for native app.
	}
}

class CordovaAppOptions extends NativeAppOptions {
	readonly historyOptions = {
		root: "index.html#",
	};

	readonly inCordova = true;

	// Hide links to the store in the apps.
	readonly hideStoreLinks = true;

	getContentPrefix(): string {
		return "content";
	}
}

class IosAppOptions extends CordovaAppOptions implements AppOptions {
	readonly inIOSApp = true;
	readonly inElectron = false;
}

class AndroidAppOptions extends CordovaAppOptions implements AppOptions {
	readonly forceLoadVideosRemotely = true;
	readonly inElectron = false;
}

class ElectronAppOptions extends CordovaAppOptions implements AppOptions {
	readonly inElectron = true;
}

function appOptionsFactory(): Readonly<AppOptions> {
	if ((window as any).NATIVE_APP) {
		if (/android/i.exec(window.navigator.userAgent)) {
			return new AndroidAppOptions();
		} else if (/electron/i.exec(window.navigator.userAgent)) {
			return new ElectronAppOptions();
		} else {
			return new IosAppOptions();
		}
	} else {
		return new WebAppOptions();
	}
}
export const appOptions = appOptionsFactory();
(window as any).appOptions = appOptions;
